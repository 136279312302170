<template>
    <el-dialog :close-on-click-modal='false' custom-class="custom-dialog" :append-to-body="true" :modal-append-to-body="true" :visible.sync="dialogVisible" :width="element.options.inputBtnWidth" :title="element.options.inputBtnTitle" top="10vh">
        <div class="popup-content1">
            <el-alert v-show="isShowWarning" title="两次密码输入不一致" type="warning" show-icon />
            <el-form ref="repwsInfoform" :model="repwsInfo" label-position="top">
                <el-form-item  prop="USERNAME" label="用户名">
                    <el-input v-model="userInfo.USERNAME" disabled></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="NEWPASSWORD" :rules="[{ message: '必须填写', required: true, trigger: 'change'}]">
                    <el-input  v-model="repwsInfo.NEWPASSWORD"  placeholder='请输入新密码' show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="CHECKPASSWORD" :rules="[{ message: '必须填写', required: true, trigger: 'change'}]">
                    <el-input  v-model="repwsInfo.CHECKPASSWORD" placeholder='请确认密码' show-password></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="determineButton">确 定</el-button>
        </span>
    </el-dialog>
  </template>

  <script>
  //引入vuex快捷方法
import {mapState} from "vuex";
import utils from "../../../../utils";
  export default {
    name: 'pwdDialog',
    data() {
      return {
        dialogVisible: false,
        element: {
            options: {
                inputBtnWidth: '600px',
                inputBtnTitle: '修改密码'
            }
        },

        isShowWarning: false,
        repwsInfo:{
            NEWPASSWORD:"",//新密码
            CHECKPASSWORD:"",//确认密码
        },// 修改密码的信息
        userInfo: {},
      }
    },
    computed:{
        customEventInfo(){
            return this.currentCustomEventInfo
        },
        app(){
            var str = this.$route.params.pathMatch
            var arr = str.split('/')
            return arr[1]
        },
        ...mapState(["currentuserinfo",]), //refreshMainTable刷新主表标拾
        //当前应用信息
        currentAppInfo(){
            return this.customEventInfo[this.app]
        },
    },



    methods: {
        show(item) {
            /**
             * mainTableSelectData 主列表选中的数据
             */
            var app = this.$route.params.app;
            var {mainTableSelectData} = this.currentAppInfo;
            this.userInfo = mainTableSelectData[0]
            this.dialogVisible = true
            this.$nextTick(_=>{
                this.$refs.repwsInfoform.resetFields();
            })
        },
        determineButton() {
            this.isShowWarning = false;
            this.repwsInfo.OLDPASSWORD = this.userInfo.PASSWORD
            this.$refs.repwsInfoform.validate((valid)=>{
                if(valid){
                    var {NEWPASSWORD,CHECKPASSWORD} = this.repwsInfo;

                    if(NEWPASSWORD == CHECKPASSWORD) {//修改密码
                        this.isShowWarning = false;
                        const url = Config.dev_url + '/api-apps/users/modifyPassword';
                        this.$axios.put(url, {
                            ID: this.userInfo['ID'],
                            NEWPASSWORD: Utils.encrypt(NEWPASSWORD,"hAw6eqnFLKxpsDv3"),
                        }).then((res) => {
                            if (res.data.code == 0) {//code == 0是后台返回的请求成功的信息
                                this.$message.success('修改成功');
                                setTimeout(() => {
                                    this.isShowWarning = false;
                                    this.dialogVisible = false
                                }, 500);

                            } else {
                            this.$message.warning(res.data.msg);
                            }
                        });
                    } else {
                        this.isShowWarning = true;
                    }
                }
            })
        }
    }
  }
  </script>

  <style></style>

